<template>
    <div>
      <!-- Start Header Area -->
      <Header>
        <img slot="logo" :src="logo" />
      </Header>
      <!-- End Header Area -->
  
      <!-- Start Breadcrump Area  -->
      <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-center rn-page-title pt--100">
                <h2 class="heading-title theme-gradient">
                  Major Project: Learning Experience Design Proposal
                </h2>
                <p>Module 3 Assignment</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Breadcrump Area  -->
  
      <!-- Start Portfolio Details Area  -->
      <div class="rn-portfolio-details ptb--120 bg_color--1">
        <v-container>
          <v-row>
            <v-col>
              <div class="portfolio-details">
                <div class="row row--35 mb--50">
                  <div class="col-lg-12">
                    <div class="inner">
                      <div class="section-title">
                        <h2 class="heading-title">Part 1: Context, Scope, & Rationale</h2>
                        <div
                          class="description mt--30"
                          style="text-align: justify !important"
                        >
                        <p><a class="btn-default btn-border" href="/documents/Learning Experience Design Guide.docx">Download Document</a></p>
                        <h4>Learning Experience Title</h4>
                          <p>
                            Full-Stack Web Development: Preparing Computer Science Students for Modern Web Applications
                          </p>
                          <h4>Abstract</h4>
                          <p>This 15-week learning experience is designed for undergraduate Computer Science students, focusing on full-stack web development. The course will provide comprehensive training on both frontend and backend technologies, covering modern web development frameworks, databases, and deployment techniques. Students will engage in hands-on projects, assessments, and lab work designed to simulate real-world development environments. By the end of the course, students will have built and deployed a fully functioning web application, gaining skills that are immediately applicable in professional settings.</p>
                          <h4>Learner Audience / Primary Users</h4>
                          <p>
                            The primary audience is undergraduate Computer Science students, particularly those interested in web development and software engineering. These learners will typically have some foundational programming knowledge, although experience with web technologies is not required.
                          </p>
                          <h4>Educational Use</h4>
                          <p>This learning experience is designed as part of a Computer Science curriculum, specifically tailored for courses on web development. It will also serve as a professional preparation module for students planning careers in web development or full-stack engineering roles.</p>
                         <h4>Language</h4>
                         <p>English</p>
                         <h4>Material Type</h4>
                         <p>Instructional Material, including:

                            <ul>
                                <li>Videos</li>
                                <li>Hands-on Lab Activities</li>
                                <li>Projects</li>
                                <li>Assessments</li>
                                <li>Course-related Reading Materials</li>
                            </ul>
                         </p>
                        <h4>Keywords</h4>
                        <p>Full-stack development, Web development, Frontend, Backend, JavaScript, Node.js, Databases, Deployment, APIs, Real-world applications</p>
                        <h4>Time Required for Learning Experience</h4>
                        <p>This is a 15-week course, with approximately 5-6 hours per week of videos, labs, project work, and assessments, totaling around 75-90 hours of learning.</p>
                        <h4>Target Skills</h4>
                        <p>
                            <ul>
                                <li>Building dynamic, responsive web applications using modern frameworks</li>
                                <li>Developing and integrating APIs for communication between frontend and backend</li>
                                <li>Working with databases (SQL and NoSQL)</li>
                                <li>Version control and collaborative development using Git and GitHub</li>
                                <li>Deploying applications on cloud platforms</li>
                            </ul>
                        </p>
                        <h4>Learning Objectives</h4>
                        <p>By the end of this learning experience, learners will be able to:
                            <ol>
                                <li>Build a full-stack web application from scratch using modern web technologies.</li>
                                <li>Integrate frontend and backend components using APIs.</li>
                                <li>Set up, manage, and query both SQL and NoSQL databases.</li>
                                <li>Implement user authentication and secure data handling.</li>
                                <li>Deploy web applications on cloud platforms (e.g., AWS, Heroku).</li>
                                <li>Collaborate effectively using Git and GitHub for version control.</li>
                            </ol>
                        </p>
                        <h4>Prior Knowledge</h4>
                        <p>Learners should have foundational programming skills in a language such as JavaScript or Python. Basic understanding of HTML and CSS will be helpful but is not required.</p>
                        <h4>Required Resources</h4>
                        <p>
                            <ul>
                                <li>A computer with access to a code editor (VSCode preferred)</li>
                                <li>Internet access for collaborative tools and cloud services</li>
                                <li>Git and GitHub accounts for version control</li>
                                <li>Accounts for cloud hosting services (e.g., AWS, Heroku, DigitalOcean)</li>
                                <li>Access to educational platforms for video lessons (if applicable)</li>
                            </ul>
                        </p>
                        <hr>
                        <h4>Rationale</h4>
                        <p>The rationale for this learning experience is rooted in the increasing demand for full-stack web developers in the tech industry. As Computer Science students prepare for careers, they need practical experience that mirrors the challenges they will face in the real world. This course bridges the gap between theory and practice by offering a hands-on, project-based approach to full-stack development. Students will gain a deep understanding of both frontend and backend technologies, enabling them to build dynamic, scalable, and secure web applications.</p>
                        <p>This course also aligns with my teaching philosophy, which emphasizes hands-on, real-world experience. By integrating full-stack development with best practices in version control, security, and cloud deployment, students will acquire skills that are directly applicable to industry standards. The 15-week structure allows for an in-depth exploration of each component, from building interfaces to deploying fully functional applications.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Portfolio Details Area  -->
  
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from "../../components/header/HeaderThree";
  import Footer from "../../components/footer/FooterTwo";
  import feather from "feather-icons";
  
  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        items: [
          {
            thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
        ],
        socialList: [
          {
            url: "https://www.facebook.com/",
            icon: "facebook",
          },
          {
            url: "https://www.linkedin.com/",
            icon: "linkedin",
          },
          {
            url: "https://instagram.com/",
            icon: "instagram",
          },
          {
            url: "https://twitter.com/",
            icon: "twitter",
          },
        ],
        moreProjects: [
          {
            src: require("../../assets/images/portfolio/related-image-01.jpg"),
            title: "Digital Analysis",
            categorie: "Technique",
          },
          {
            src: require("../../assets/images/portfolio/related-image-02.jpg"),
            title: "Plan Management",
            categorie: "Planning",
          },
        ],
        index: null,
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
  </script>
  